import { getList } from '@/requests/api/get-list';
import { type Invoice, type Tenant } from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';

// Custom handler for Invoice
export async function handleCustomInvoiceNestedCase(
  listResponse: TCustomList<Invoice>
): Promise<TCustomList<Invoice>> {
  const recipients = listResponse.data.flatMap(
    (invoice) => invoice.invoiceRecipients
  );

  const tenantIds = listResponse.data.reduce<Set<string>>((acc, invoice) => {
    invoice.invoiceRecipients?.forEach((recipient) => {
      if (recipient?.tenant?.id) {
        acc.add(recipient.tenant.id);
      }
    });
    return acc;
  }, new Set());

  if (tenantIds.size === 0) return listResponse;

  const chunkedTenantIds = chunkIds(tenantIds);

  const resolvedTenantsPromises = await Promise.allSettled(
    chunkedTenantIds.map((chunk) =>
      getList<Tenant>({
        modelName: 'tenant',
        queryParams: {
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          pageSize: chunk.length,
          order: ['-createdAt'],
        },
      })
    )
  );

  const resolvedTenants: Tenant[] = [];
  const failedTenants: unknown[] = [];
  for (const promise of resolvedTenantsPromises) {
    if (promise.status === 'fulfilled') {
      resolvedTenants.push(...(promise.value.list ?? []));
    } else {
      failedTenants.push(promise.reason);
    }
  }

  // Map tenants to recipients
  const mappedRecipients = recipients.map((recipient) => {
    return {
      ...recipient,
      tenant:
        resolvedTenants.find((tenant) => tenant.id === recipient.tenant?.id) ||
        recipient.tenant,
    };
  });

  // Update invoices with enriched recipients
  const mappedListResponse: TCustomList<Invoice> = {
    data: listResponse.data.map((invoice) => ({
      ...invoice,
      invoiceRecipients: invoice.invoiceRecipients.map((recipient) => {
        const matchedRecipient = mappedRecipients.find(
          (r) => r.id === recipient.id
        );
        return matchedRecipient || recipient;
      }),
    })) as Invoice[],
  };

  return mappedListResponse;
}
