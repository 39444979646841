import type { BadgeVariant } from '@/components/ui/badge';
import type { JsonFilter } from '@/hooks/use-table-filter/types';
import { Urls } from '@/lib/urls';
import { ObjectValues } from '@/lib/utils';
import {
  getDateRange,
  type DateCategory,
  type DateRange,
} from '@/requests/hooks/utils';
import type { ModelName } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import type { Widget } from './types';

const modelNameToUrlMap: Partial<Record<ModelName, string>> = {
  apartmentcontract: Urls.dashboard.contracts.apartment,
  apartment: Urls.dashboard.apartments,
  industrialpremises: Urls.dashboard.industrialPremises,
  outdoorsection: Urls.dashboard.outdoorSections,
  parkingspot: Urls.dashboard.parkingSpots,
  commonarea: Urls.dashboard.commonAreas,
  brfapartment: Urls.dashboard.brf.brfApartments,
  industrialpremisescontract: Urls.dashboard.contracts.industrialPremises,
  parkingspotcontract: Urls.dashboard.contracts.parkingSpot,
  outdoorsectioncontract: Urls.dashboard.contracts.outdoorSection,
  othercontract: Urls.dashboard.contracts.otherContracts,
  blockcontract: Urls.dashboard.contracts.blockContracts,
  invoice: Urls.dashboard.invoicing.invoices,
  invoicepayment: Urls.dashboard.invoicing.payments,
  sensor: Urls.dashboard.imd.sensors,
  organizationuser: Urls.settings.organization.users.default,
  tenant: Urls.dashboard.tenants,
  signabledocument: Urls.dashboard.inbox.signings,
  automaticdistributionquota: Urls.dashboard.revenue.distribution.default,
  verificationgroup: Urls.dashboard.invoicing.verificationGroups,
  verification: Urls.dashboard.invoicing.verificationGroups,
  notificationdistribution: Urls.dashboard.invoicing.failedDelivieries,
};

export function getGreeting() {
  const now = DateTime.now();
  const hour = now.hour;
  if (hour >= 0 && hour <= 4) {
    return 'God natt';
  }
  if (hour > 4 && hour <= 11) {
    return 'God morgon';
  }
  if (hour > 11 && hour <= 18) {
    return 'God dag';
  }
  if (hour > 18 && hour <= 23) {
    return 'God kväll';
  }
  return 'God morgon';
}

export function extractIdentifiers(
  data: Partial<Record<string, Omit<Widget, 'modelNames'>[]>>
): string[] {
  return ObjectValues(data)
    .flatMap((items) =>
      (items ?? []).flatMap((item) =>
        item.statistics.map((stat) => stat.identifier)
      )
    )
    .filter((stat) => stat != null);
}
export function createUrl(modelName: ModelName, filters: JsonFilter) {
  if (!modelNameToUrlMap?.[modelName]) return '';
  return `${modelNameToUrlMap?.[modelName]}?filter=${encodeURIComponent(JSON.stringify(filters))}`;
}

export const subCategoryTranslationMap: Record<
  string,
  {
    title: string;
    modelName: ModelName;
  }
> = {
  apartment_contracts: {
    title: 'Lägenhetsavtal',
    modelName: 'apartmentcontract',
  },
  industrialpremises_contracts: {
    title: 'Lokalavtal',
    modelName: 'industrialpremisescontract',
  },
  parkingspot_contracts: {
    title: 'Parkeringsavtal',
    modelName: 'parkingspotcontract',
  },
  outdoorsection_contracts: {
    title: 'Utomhussektionsavtal',
    modelName: 'outdoorsectioncontract',
  },
  other_contracts: {
    title: 'Övriga avtal',
    modelName: 'othercontract',
  },
  block_contracts: {
    title: 'Blockavtal',
    modelName: 'blockcontract',
  },
  apartment: {
    title: 'Lägenheter',
    modelName: 'apartment',
  },
  industrial_premises: {
    title: 'Lokaler',
    modelName: 'industrialpremises',
  },
  outdoor_section: {
    title: 'Utomhussektioner',
    modelName: 'outdoorsection',
  },
  common_area: {
    title: 'Gemensamma ytor',
    modelName: 'commonarea',
  },
  brf_apartment: {
    title: 'Bostadsrätter',
    modelName: 'brfapartment',
  },
  parking_spot: {
    title: 'Fordonsplatser',
    modelName: 'parkingspot',
  },
  invoice: {
    title: 'Avier',
    modelName: 'invoice',
  },
  non_rental_invoice: {
    title: 'Fakturor',
    modelName: 'invoice',
  },
  invoice_payment: {
    title: 'Betalningar',
    modelName: 'invoicepayment',
  },
  imd: {
    title: 'IMD',
    modelName: 'sensor',
  },
  monitoring: {
    title: 'Godkännanden',
    modelName: 'monitoringconfiguration',
  },
  brf: {
    title: 'BRF',
    modelName: 'brfapartment',
  },
  bookkeeping: {
    title: 'Bokföring',
    modelName: 'verificationgroup',
  },
};

export const categorizingOptions = [
  {
    label: 'Påminnelse',
    value: '#d41212',
  },
];

export const widgetColors: Record<
  NonNullable<BadgeVariant['variant']>,
  string
> = {
  destructive: '#f00',
  yellow: '#fef9c3',
  blue: '#e1e7fd',
  purple: '#f1e8fd',
  orange: '#fceed8',
  cyan: '#d7f9fd',
  green: '#dcfce7',
  gray: '#7d7d7d',
  'light-gray': '#f7f7f7',
  default: '#274c77',
  outline: '#ff000000',
};

export const reverseWidgetColors: Record<string, BadgeVariant['variant']> = {
  '#f00': 'destructive',
  '#fef9c3': 'yellow',
  '#e1e7fd': 'blue',
  '#f1e8fd': 'purple',
  '#fceed8': 'orange',
  '#d7f9fd': 'cyan',
  '#dcfce7': 'green',
  '#7d7d7d': 'gray',
  '#f7f7f7': 'light-gray',
  '#274c77': 'default',
  '#ff000000': 'outline',
};
// 0 = debt
// 1 = inkasso
// 2 = kronofogden
export const reminderTranslationMap: Record<number, string> = {
  [-1]: 'system_events.invoices.reminder_invoices',
  0: 'system_events.invoices.reminder_debt_invoices',
  1: 'system_events.invoices.inkasso_invoices',
};

export const specialFuckingCases: Record<string, boolean> = {
  'system_events.invoices.reminder_invoices': true,
  'system_events.invoices.reminder_debt_invoices': true,
  'system_events.invoices.inkasso_invoices': true,
} as const;

export function processTimeline(
  timeline: string[],
  range: DateRange,
  dateCategory: DateCategory,
  debtTimeLine: {
    [date: string]: {
      [debtKind: number]: number;
    };
  },
  stat: { identifier: string }
): Map<string, number> {
  const sendoutCount = new Map<string, number>();

  for (const date of timeline) {
    const sendoutTime = DateTime.fromISO(date);
    if (sendoutTime.isValid) {
      const startDate = getDateRange(range, dateCategory)?.startDate.toMillis();
      const validStartDate = !startDate || sendoutTime.toMillis() >= startDate;

      if (validStartDate) {
        const endDate = getDateRange(range, dateCategory)?.endDate.toMillis();
        const validEndDate = !endDate || sendoutTime.toMillis() <= endDate;

        if (validEndDate) {
          for (const [kind, count] of Object.entries(
            debtTimeLine[sendoutTime.toISODate()]
          )) {
            if (
              reminderTranslationMap[parseInt(kind, 10)]?.includes(
                stat.identifier
              )
            ) {
              sendoutCount.set(
                stat.identifier,
                (sendoutCount.get(stat.identifier) ?? 0) + count
              );
            }
          }
        }
      }
    }
  }

  return sendoutCount;
}
