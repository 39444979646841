import type { JsonFilter } from '@/hooks/use-table-filter/types';
import { RectangleStackIcon } from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import type { Icon } from '../detail/components/events/utils';
import { Icons } from '../icons';

export interface FilterEnumChoice {
  title: string;
  filters: {
    operator: FilterOperator;
    value: string;
  }[];
  overrideExternalFieldName?: string;
  badgeVariant:
    | 'default'
    | 'gray'
    | 'light-gray'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'purple'
    | 'orange'
    | 'cyan'
    | 'destructive'
    | 'outline'
    | null
    | undefined;
}

export interface Filter {
  kind: FilterKind;
  title: string;
  externalFieldName: string;
  choices?: { v: string | number | boolean; d: string }[];
  enumChoices?: FilterEnumChoice[];
  relationModelName?: ModelName;
  operators: FilterOperator[];
  defaultOperator: FilterOperator;
  relationDefaultFilters?: JsonFilter;
  icon?: Icon;
}

export type FilterValue = string | number | string[] | boolean | null;

interface DefaultFilter {
  kind: FilterKind;
  operators: FilterOperator[];
  defaultOperator: FilterOperator;
  icon?: Icon;
}

export interface AppliedFilter {
  operator: FilterOperator;
  externalFieldName: string;
  value: FilterValue;
}

export const RELATION_FILTER = 're' as const;
export const STRING_FILTER = 'st' as const;
export const SELECT_FILTER = 'se' as const;
export const DATE_FILTER = 'dt' as const;
export const BOOLEAN_FILTER = 'bo' as const;
export const NUMBER_FILTER = 'no' as const;
export const TAG_FILTER = 'tg' as const;
export const NULL_FILTER = 'nu' as const;
export const ENUM_FILTER = 'en' as const; // special case for i.e statuses on contracts, always rendered

export const FILTER_KINDS = {
  RELATION_FILTER: RELATION_FILTER,
  STRING_FILTER: STRING_FILTER,
  SELECT_FILTER: SELECT_FILTER,
  DATE_FILTER: DATE_FILTER,
  BOOLEAN_FILTER: BOOLEAN_FILTER,
  NUMBER_FILTER: NUMBER_FILTER,
  TAG_FILTER: TAG_FILTER,
  NULL_FILTER: NULL_FILTER,
  ENUM_FILTER: ENUM_FILTER,
} as const;

export type FilterKind = (typeof FILTER_KINDS)[keyof typeof FILTER_KINDS];

export type FilterOperator =
  | '__in'
  | '__in!'
  | '__iexact'
  | '__iexact!'
  | '__icontains'
  | '__icontains!'
  | '__lte'
  | '__lte!'
  | '__gte'
  | '__gte!'
  | '__lt'
  | '__lt!'
  | '__gt'
  | '__gt!'
  | '__isnull'
  | '__isnull!'
  | 'noop';

export const OPERATORS = {
  IN: ['__in', '__in!'], // in for arrays / booleans
  EXACT: ['__iexact', '__iexact!'], // exact for strings/numbers
  CONTAINS: ['__icontains', '__icontains!'],
  LTE: ['__lte', '__lte!'], // less than or equal for dates/numbers
  GTE: ['__gte', '__gte!'], // greater than or equal for dates/numbers
  LT: ['__lt', '__lt!'], // less than for dates/numbers
  GT: ['__gt', '__gt!'], // greater than for dates/numbers
  ISNULL: ['__isnull', '__isnull!'], // null/not null
  NOOP: ['noop'], // for when we arent allowed to add any operators, no-operation
} as const satisfies Record<string, FilterOperator[]>;

export const getReadableOperator = (
  operator: FilterOperator,
  kind: FilterKind
) => {
  switch (operator) {
    case '__in': {
      if (kind === BOOLEAN_FILTER) {
        return 'är';
      } else {
        return 'innehåller';
      }
    }
    case '__in!': {
      if (kind === BOOLEAN_FILTER) {
        return 'är inte ';
      } else {
        return 'innehåller inte';
      }
    }
    case '__iexact':
      return 'är';
    case '__iexact!':
      return 'är inte';
    case '__icontains':
      return 'innehåller';
    case '__icontains!':
      return 'innehåller inte';
    case '__lte': {
      switch (kind) {
        case 'dt':
          return 'innan eller lika med';
        default:
          return 'mindre än eller lika med';
      }
    }
    case '__lte!': {
      switch (kind) {
        case 'dt':
          return 'inte innan eller lika med';
        default:
          return 'inte mindre än eller lika med';
      }
    }
    case '__gte': {
      switch (kind) {
        case 'dt':
          return 'efter eller lika med';
        default:
          return 'mer än eller lika med';
      }
    }
    case '__gte!': {
      switch (kind) {
        case 'dt':
          return 'inte efter eller lika med';
        default:
          return 'inte mer än eller lika med';
      }
    }
    case '__lt': {
      switch (kind) {
        case 'dt':
          return 'innan';
        default:
          return 'mindre än';
      }
    }
    case '__lt!': {
      switch (kind) {
        case 'dt':
          return 'inte innan';
        default:
          return 'inte mindre än';
      }
    }
    case '__gt': {
      switch (kind) {
        case 'dt':
          return 'efter';
        default:
          return 'mer än';
      }
    }
    case '__gt!': {
      switch (kind) {
        case 'dt':
          return 'inte efter';
        default:
          return 'inte mer än';
      }
    }
    case '__isnull':
      return 'har inget värde';
    case '__isnull!':
      return 'har ett värde';
    case 'noop':
      return 'är';
  }
};

export const defaultRelationFilter: DefaultFilter = {
  kind: RELATION_FILTER,
  operators: [...OPERATORS.IN],
  defaultOperator: OPERATORS.IN[0],
  icon: Icons.relation,
};

export const defaultStringFilter: DefaultFilter = {
  kind: STRING_FILTER,
  operators: [...OPERATORS.CONTAINS, ...OPERATORS.EXACT, ...OPERATORS.ISNULL],
  defaultOperator: OPERATORS.CONTAINS[0],
  icon: Icons.string,
};

export const defaultDateFilter: DefaultFilter = {
  kind: DATE_FILTER,
  operators: [
    ...OPERATORS.LTE,
    ...OPERATORS.GTE,
    ...OPERATORS.IN,
    ...OPERATORS.LT,
    ...OPERATORS.GT,
  ],
  defaultOperator: OPERATORS.IN[0],
  icon: Icons.date,
};

export const defaultNumberFilter: DefaultFilter = {
  kind: NUMBER_FILTER,
  operators: [
    ...OPERATORS.EXACT,
    ...OPERATORS.LTE,
    ...OPERATORS.GTE,
    ...OPERATORS.LT,
    ...OPERATORS.GT,
  ],
  defaultOperator: OPERATORS.GTE[0],
  icon: Icons.number,
};

export const defaultBooleanFilter: DefaultFilter = {
  kind: BOOLEAN_FILTER,
  operators: [...OPERATORS.IN],
  defaultOperator: OPERATORS.IN[0],
  icon: Icons.boolean,
};
export const defaultNullFilter: DefaultFilter = {
  kind: NULL_FILTER,
  operators: [...OPERATORS.ISNULL],
  defaultOperator: OPERATORS.ISNULL[1],
  icon: Icons.boolean,
};

export const defaultTagFilter: DefaultFilter = {
  kind: TAG_FILTER,
  operators: OPERATORS.NOOP,
  defaultOperator: OPERATORS.NOOP[0],
};

export const defaultSelectFilter: DefaultFilter = {
  kind: SELECT_FILTER,
  operators: [...OPERATORS.IN, ...OPERATORS.EXACT],
  defaultOperator: OPERATORS.IN[0],
  icon: RectangleStackIcon,
};

export const defaultEnumFilter: DefaultFilter = {
  kind: ENUM_FILTER,
  operators: OPERATORS.IN,
  defaultOperator: OPERATORS.IN[0],
};

export const customFilterConfig = {
  custom_invoice_filter: {
    credit_rental_invoices: 'rental_invoice=true&total_value__lte=-1',
    credit_invoices: 'rental_invoice=false&total_value__lte=-1',
    not_attested: `attested_date__isnull=true`,
    failed_attested: `attested_date__isnull=false&ocr_number__isnull=true`,
    reminders: `attested_date__isnull=false&closed_time__isnull=true&debt_invoice_counts_kind_0__gt=0`,
    reminderspending: `attested_date__isnull=false&closed_time__isnull=true&due_date__lt=${DateTime.now().toISODate()}&reminder_amount__gte=1&automatic_debt_invoicing_paused=false`,
    debt: `attested_date__isnull=false&closed_time__isnull=true&debt_invoice_counts_kind_1__gt=0`,
    overdue: `attested_date__isnull=false&closed_time__isnull=true&due_date__lt=${DateTime.now().toISODate()}`,
    fully_paid: `attested_date__isnull=false&closed_time__isnull=false`,
    // overdue_not_handled: `attested_date__isnull=false&closed_time__isnull=true&reminder_amount=0&due_date__lt=${DateTime.now().toISODate()}`,
    sent: `attested_date__isnull=false&closed_time__isnull=true&due_date__gte=${DateTime.now().toISODate()}&distribution_date__isnull=false`,
    not_sent: `attested_date__isnull=false&closed_time__isnull=true&due_date__gte=${DateTime.now().toISODate()}&distribution_date__isnull=true`,
  },
  custom_verification_group_filter: {
    attested: `confirmation_date__isnull=false`,
    not_attested: `confirmation_date__isnull=true`,
  },
  custom_signable_doc_filter: {
    initiated_not_started: `signing_initiated=true&signing_started_time__isnull=true`,
    not_initiated: `signing_initiated=false&external_sign_engine__isnull=false`,
    manual: `signing_initiated=false&external_sign_engine__isnull=true`,
  },
  custom_sensor_filter: {
    not_matched: `apartment__isnull=true&parking_spot__isnull=true&industrial_premises__isnull=true&common_area__isnull=true`,
  },
  custom_payments_filter: {
    distributed: `invoice__isnull=false&annulled=false`,
    not_handled: `is_unhandled=true&annulled=false`,
    not_matched: `is_unmatched=true&annulled=false`,
    annulled: `annulled=true`,
    overpayment: `is_overpayment=true&annulled=false`,
    matched_manual: `matched_date__isnull=true&invoice__isnull=true&annulled=false&original_invoice__isnull=false`,
  },
  custom_vacancyinvoice_filter: {
    attested: `attested_date__isnull=false`,
    not_attested: `attested_date__isnull=true`,
    annulled: `annulled=true`,
  },
};
