'use client';

import { DescriptionTooltip } from '@/components/form/dynamic-field/components/description-tooltip';
import { Badge } from '@/components/ui/badge';
import type { Invoice } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

export function InvoiceStatus({ invoice }: { invoice: Invoice }) {
  if ((invoice.invoiceValuesMeta?.total_value ?? 0) < 0) {
    return <Badge variant={'outline'}>Krediterad</Badge>;
  }

  if (invoice.closedTime) {
    return <Badge variant={'green'}>Slutbetald</Badge>;
  }
  // has actually sent debt
  // @ts-expect-error typed as string
  if (invoice.debtInvoiceCounts?.kinds?.['1'] > 0) {
    return invoice.automaticDebtInvoicingPaused ? (
      <Badge variant='light-gray'>Kravhantering (Pausad)</Badge>
    ) : (
      <Badge variant='purple'>Kravhantering</Badge>
    );
  }

  // reminders

  // has actually sent reminders
  // @ts-expect-error typed as string
  if (invoice.debtInvoiceCounts?.kinds?.['0'] > 0) {
    return invoice.automaticDebtInvoicingPaused ? (
      <Badge variant='light-gray'>Påminnelse skickad (Pausad)</Badge>
    ) : (
      <Badge variant='purple'>Påminnelse skickad</Badge>
    );
  } else if (
    invoice.reminderAmount &&
    invoice.reminderAmount > 0 &&
    invoice.attestedDate &&
    DateTime.now() > DateTime.fromISO(invoice.dueDate).plus({ days: 1 })
  ) {
    if (invoice.automaticDebtInvoicingPaused) {
      return <Badge variant='gray'>Påminnelser pausade</Badge>;
    } else {
      return <Badge variant='purple'>Påminnelse kommer skickas</Badge>;
    }
  }

  // no reminders but overdue
  if (
    DateTime.now() > DateTime.fromISO(invoice.dueDate).plus({ days: 1 }) &&
    !invoice.closedTime &&
    invoice.attestedDate
  ) {
    return <Badge variant={'destructive'}>Förfallen</Badge>;
  }

  if (invoice.distributionDate && invoice.attestedDate && !invoice.closedTime) {
    return <Badge variant={'blue'}>Skickad</Badge>;
  }

  if (!invoice.attestedDate) {
    return <Badge variant={'yellow'}>Ej attesterad</Badge>;
  } else {
    if (invoice.invoiceNumber && invoice.ocrNumber) {
      return <Badge variant={'green'}>Attesterad</Badge>;
    } else {
      return (
        <Badge variant={'destructive'}>
          Misslyckad attestering{' '}
          <DescriptionTooltip
            className='max-w-[200px] whitespace-normal text-xs font-normal'
            description='Attesteringen av denna avi/faktura misslyckades. Försök igen.'
          />
        </Badge>
      );
    }
  }
}
