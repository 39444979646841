import { IntegrationServiceDataMap } from '@/app/settings/organization/integrations/utils';
import type { IdentifierMapping } from '..';
import {
  getContractMapping,
  getInvoiceMapping,
  getObjectsMapping,
  getTenantMapping,
} from './utils';

export const toHandleIdentifierData: IdentifierMapping[string] = {
  apartment_contracts: getContractMapping('apartmentcontract'),
  industrialpremises_contracts: getContractMapping(
    'industrialpremisescontract'
  ),
  parkingspot_contracts: getContractMapping('parkingspotcontract'),
  outdoorsection_contracts: getContractMapping('outdoorsectioncontract'),
  other_contracts: getContractMapping('othercontract'),
  block_contracts: getContractMapping('blockcontract'),
  organization_user: {
    active_users: {
      modelName: 'organizationuser',
      filters: {
        is_active: {
          noop: true,
        },
        last_login: { __isnull: false },
      },
    },
    invited_users: {
      modelName: 'organizationuser',
      filters: {
        is_active: {
          noop: false,
        },
        hotp_invite_sendout_time: { __isnull: false },
      },
    },
    inactivated_users: {
      modelName: 'organizationuser',
      filters: {
        is_active: {
          noop: false,
        },
      },
    },
  },
  tenant: getTenantMapping('tenant'),
  brf_member: getTenantMapping('tenant', true),
  apartment: getObjectsMapping('apartment'),
  industrial_premises: getObjectsMapping('industrialpremises'),
  outdoor_section: getObjectsMapping('outdoorsection'),
  parking_spot: getObjectsMapping('parkingspot'),
  common_area: getObjectsMapping('commonarea'),
  brf_apartment: getObjectsMapping('brfapartment'),
  invoice: getInvoiceMapping('invoice'),
  non_rental_invoice: getInvoiceMapping('invoice', true),
  invoice_payment: {
    non_matched_payments: {
      modelName: 'invoicepayment',
      filters: {
        is_unmatched: {
          noop: true,
        },
        annulled: {
          noop: false,
        },
      },
    },
    overpaid_payments: {
      modelName: 'invoicepayment',
      filters: {
        is_overpayment: {
          noop: true,
        },
        annulled: {
          noop: false,
        },
      },
    },
    unhandled_payments: {
      modelName: 'invoicepayment',
      filters: {
        is_unhandled: {
          noop: true,
        },
        annulled: {
          noop: false,
        },
      },
    },
  },
  imd: {
    not_matched_sensors: {
      modelName: 'sensor',
      filters: {
        apartment: { __isnull: true },
        common_area: { __isnull: true },
        industrial_premises: { __isnull: true },
        parking_spot: { __isnull: true },
        brf_apartment: { __isnull: true },
      },
    },
  },
  monitoring: {
    pending_approvals: {
      modelName: 'monitoringconfiguration',
      noFilterReturn: true,
      filters: {
        is_relevant_for_approving: { noop: true },
        mt_approved_time: {
          __isnull: true,
        },
        mt_denied_time: {
          __isnull: true,
        },
      },
    },
    retro_activity_proposals: {
      group_modelNames: [
        'retroactiveapartmentrevenueproposal',
        'retroactiveindustrialpremisesrevenueproposal',
        'retroactiveparkingspotrevenueproposal',
        'retroactiveoutdoorsectionrevenueproposal',
      ],
      filters: {
        accepted: { __isnull: true },
      },
    },
    distribution_quota_proposals: {
      modelName: 'automaticdistributionquota',
      filters: {
        accepted: { __isnull: true },
        hidden: { noop: false },
        apartment: { '__isnull!': true },
        industrial_premises: { '__isnull!': true },
        outdoor_section: { '__isnull!': true },
        parking_spot: { '__isnull!': true },
        brf_apartment: { '__isnull!': true },
      },
    },
  },
  brf: {
    has_no_listed_control_tasks: {
      modelName: 'brfapartment',
      filters: {},
    },
    has_no_active_invoicing_configuration: {
      modelName: 'brfapartment',
      filters: {
        has_active_invoicing_configuration: { noop: false },
      },
    },
    has_no_indefinite_invoicing_configuration: {
      modelName: 'brfapartment',
      filters: {
        has_indefinite_invoicing_configuration: { noop: false },
      },
    },
  },
  bookkeeping: {
    verification_groups_to_attest: {
      modelName: 'verificationgroup',
      filters: {
        confirmation_date: { __isnull: true },
      },
    },
    verification_not_exported_fortnox: {
      modelName: 'verification',
      filters: {
        exported_for_import_in_file: { __isnull: true },
        connected_integration_applications: {
          noop: IntegrationServiceDataMap.FORTNOX?.applicationId,
        },
      },
      noFilterReturn: true,
    },
    verification_not_exported_visma: {
      modelName: 'verification',
      filters: {
        exported_for_import_in_file: { __isnull: true },
        connected_integration_applications: {
          noop: IntegrationServiceDataMap.VISMA_EEKONOMI?.applicationId,
        },
      },
      noFilterReturn: true,
    },
  },
};
